/* Coverage:
 Statements: 78%
 Branches: 71%
 Functions: 56%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { Button, Card, CardBody, CardHeader, Tooltip } from '@heroui/react'
import { ValueGetterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Piano } from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLoaderData, useNavigate, useParams } from 'react-router'
import { ComponentErrorBoundaryWrapper } from '~/components/ErrorBoundary'
import { agGridTheme } from '~/config/themes/agGridTheme'
import { useServerContext } from '~/hooks/useServerContext'
import useStrings from '~/hooks/useStrings'
import { ExerciseDrill } from '~/lib/types'
import { LoaderData } from '~/routes/_layout.$lang._index'

export const StudentHomePage = () => {
  const { userData, history, nextExercise } = useLoaderData<LoaderData>()
  const navigate = useNavigate()
  const { t } = useStrings()
  const { lang } = useParams()
  const gridRef = useRef<AgGridReact>(null)
  const { tracksById } = useServerContext()
  const [currentProgram, setCurrentProgram] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (userData.enrollments.length > 0) {
      setCurrentProgram(userData.enrollments.find(e => e.practicing)?.program.programRecord.name)
    }
  }, [userData])

  const handleExercisePractice = (ed: ExerciseDrill) => {
    navigate(`/${lang || 'en'}/exercise/${ed.exercise.id}`)
  }

  const handlePracticeNow = () => {
    if (nextExercise) {
      navigate(`/${lang || 'en'}/exercise/${nextExercise.id}`)
    }
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'actions',
        width: 50,
        sortable: false,
        filter: false,
        cellRenderer: (params: any) => {
          if (!params.data) return null
          return (
            <div
              className="flex space-x-2 h-full items-center"
              aria-label={`${t('Actions for exercise')} ${t(params.data.title)}`}
            >
              <Tooltip content={t('Practice Again')} placement="bottom" showArrow>
                <button
                  onClick={() => params.data && handleExercisePractice(params.data)}
                  disabled={!params.data}
                  aria-label={`${t('Practice')} ${t(params.data.title)} ${t('again')}`}
                  className="p-2 hover:bg-grey-100 rounded-full transition-colors"
                >
                  <Piano size={20} />
                </button>
              </Tooltip>
            </div>
          )
        },
      },
      {
        headerName: t('Date'),
        field: 'createdAt',
        width: 100,
        valueGetter: (params: ValueGetterParams<ExerciseDrill>) => {
          const utcDate = params.data?.drill.createdAt
          if (!utcDate) return ''
          return new Date(utcDate).toLocaleDateString()
        },
      },
      {
        headerName: t('Title'),
        field: 'title',
        width: 400,
        valueGetter: (params: ValueGetterParams<ExerciseDrill>) => params.data?.drill.title ?? '',
      },
      {
        headerName: t('Track'),
        field: 'trackId',
        width: 150,
        valueGetter: (params: ValueGetterParams<ExerciseDrill>) =>
          tracksById[params.data?.drill.trackId ?? -1]?.name ?? '',
        filterParams: {
          filterOptions: ['contains'],
        },
      },
    ],
    [handleExercisePractice],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    }
  }, [])

  return (
    <ComponentErrorBoundaryWrapper message={t('Unable to load exercises')}>
      <div className="flex flex-col gap-2" role="main" aria-label={t('Exercise history')}>
        <Card className="flex flex-col gap-4 w-full sm:w-[500px] md:w-[750px] mx-auto mb-14">
          <CardHeader className="mt-4 flex flex-col gap-1">
            <div className="mx-auto">{t('Currently Practicing')}</div>
            <h2 className="text-3xl mx-auto">{currentProgram}</h2>
          </CardHeader>
          <CardBody>
            <Button className="w-fit mx-auto text-primary flex items-center gap-3 mb-4" onPress={handlePracticeNow}>
              <Piano size={32} />
              {t('Practice Now')}
            </Button>
            <Link
              to={`/${lang || 'en'}/account`}
              className="mx-auto text-xs text-secondary mb-8 "
              aria-label={t('Change practice program')}
            >
              {t('Practice Something Else')}
            </Link>
          </CardBody>
        </Card>
        <section className="h-96 mx-auto w-full sm:w-[750px]" aria-label={t('Drills grid')}>
          <h2 className="text-xl text-center mb-4 mx-auto">{t('Completed Exercises')}</h2>
          <AgGridReact
            ref={gridRef}
            theme={agGridTheme}
            rowData={history}
            columnDefs={columnDefs as any[]}
            defaultColDef={defaultColDef}
            getRowId={params => params.data?.exercise.id.toString() || ''}
            headerHeight={48}
            rowHeight={48}
            suppressRowDrag={true}
          />
        </section>
      </div>
    </ComponentErrorBoundaryWrapper>
  )
}
