import useStrings from '~/hooks/useStrings'

export default function HomePage() {
  const { t } = useStrings()

  return (
    <main className="w-full sm:px-4 sm:py-8">
      <div className="text-center bg-purple flex flex-col items-center justify center">
        <h1 className="text-lg sm:text-2xl text-yellowVivid-500 mt-4 mb-4" aria-label="My Piano Trainer">
          My Piano Trainer
        </h1>
        <div className="relative mx-auto w-[100%] sm:w-[512px]">
          <img
            src="/images/piano.png"
            alt="My Piano Trainer"
            className="w-[100%] sm:h-[340px] relative z-0"
            aria-hidden="true"
          />
          {/* Top left text that bleeds left */}
          <h2 className="absolute left-[2%] sm:left-[5%] top-[3%] sm:top-[10%] sm:-translate-x-1/2 text-yellowVivid-500 italic text-3xl sm:text-6xl [text-shadow:8px_8px_8px_hsl(25_82%_26%_/_0.5)] z-10">
            Step by Step
          </h2>

          {/* Bottom right text that bleeds right */}
          <h2 className="absolute right-[5%] bottom-[10%] sm:translate-x-1/2 text-yellowVivid-500 italic text-2xl sm:text-4xl text-left [text-shadow:8px_8px_8px_hsl(25_82%_26%_/_0.5)] z-10">
            To Mastery
          </h2>
        </div>
      </div>

      {/* Hidden text for screen readers providing more context */}
      <div className="sr-only">
        {t(
          'Welcome to My Piano Trainer, a comprehensive platform for learning and practicing piano. The application is currently under development.',
        )}
      </div>
    </main>
  )
}
