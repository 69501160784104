/* Coverage:
 Statements: 0%
 Branches: 0%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { data, LoaderFunction, useLoaderData } from 'react-router'
import HomePage from '~/components/pages/HomePage'
import { StudentHomePage } from '~/components/pages/StudentHomePage'
import useStrings from '~/hooks/useStrings'
import { ExerciseRecord } from '~/lib/schema'
import { ExerciseDrill, UserData } from '~/lib/types'
import ExerciseService from '~/services/ExerciseService.server'
import userDataSet from '~/services/UserService.server'

export type LoaderData = {
  userData: UserData
  history: ExerciseDrill[]
  nextExercise: ExerciseRecord | undefined
}

export const loader: LoaderFunction = async ({ request }) => {
  const uds = await userDataSet(request)
  const exerciseService = new ExerciseService(uds.userData)
  const history = uds.userData.userId ? await exerciseService.exerciseHistory() : []
  const nextExercise = uds.userData.userId ? await exerciseService.nextExercise() : undefined
  return data(
    {
      userData: uds.userData,
      history,
      nextExercise,
    },
    {
      headers: {
        'Set-Cookie': await uds.cookieHeader(),
      },
    },
  )
}
export default function Index() {
  const { userData, history, nextExercise } = useLoaderData<LoaderData>()
  const { t } = useStrings()

  if (userData.userId) {
    return <StudentHomePage />
  }
  return <HomePage />
}
